@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.ui-v2-rotating-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 300px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-image: url(../assets/images/overlay-rotation.png);
  animation: rotate 12s linear infinite;
  pointer-events: none;
}

.ui-v2-rotating-overlay::after {
  content: '';
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: radial-gradient(rgb(255 0 0 / 40%), rgba(0, 100, 193, 0.007) 70%);
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@media screen and (max-width: 480px) {
  .ui-v2-rotating-overlay {
    width: 230px;
    height: 230px;
    background-image: url(../assets/images/overlay-rotation-md.png);
  }
  .ui-v2-rotating-overlay::after {
    width: 230px;
    height: 230px;
  }
}

@media screen and (max-width: 399px) {
  .ui-v2-rotating-overlay {
    width: 210px;
    height: 210px;
    background-image: url(../assets/images/overlay-rotation-sm.png);
  }
  .ui-v2-rotating-overlay::after {
    width: 210px;
    height: 210px;
  }
}
